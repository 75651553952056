import React, { useRef, useEffect }  from 'react';
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import { gsap } from "gsap";
import bulmaCollapsible from '@creativebulma/bulma-collapsible/src/js/index.js'


import Layout from '../components/Layout'

// Hero
import HeroThumbnail from '../img/hero-thumbnail-v2.png'
import HeroElement from '../img/hero/element.png'
import HeroElementSquare from '../img/square-pattern.png'
import Buyer from '../img/hero/buyer-1.png'
import Paul from '../img/hero/buyer-2.png'
import Bianca from '../img/hero/buyer-3.png'
import Scott from '../img/hero/buyer-4.png'
import Japoy from '../img/hero/buyer-5.png'
import Jeff from '../img/hero/buyer-6.png'
import Pao from '../img/hero/owner.png'

// Element
import DoubleCircle from '../img/double-circle-element.png'
import DoubleSquared from '../img/double-square-element.png'

// New KKB
import ExpandReach from '../img/icons/expand.svg'
import ReduceSpend from '../img/icons/reduce.svg'
import SecureOrders from '../img/icons/secure.svg'

// Features
import GroupPage from '../img/features/group-page.jpg'
import GroupPageElement from '../img/features/group-buy-element.png'
import PaymentCheckout from '../img/features/payments.png'
import PaymentCheckoutElement from '../img/features/payments-element.png'
import Rules from '../img/features/rules.png'
import RulesElement from '../img/features/set-rules-element.png'
import Borzo from '../img/features/borzo.png'
import BorzoElement from '../img/features/same-day-element.png'

// How it works
import SignUp from '../img/sign-up.svg'
import Create from '../img/create.svg'
import Cascade from '../img/cascade.svg'
import Chill from '../img/chill.svg'

// Contact
import ContactElement from '../img/contact-us-element.png'




function IndexPageTemplate() {

  const boxRef = useRef();
  const ownerMessage = useRef();
  const chippersRef = useRef();


  useEffect(() => {
    // Animation
    // gsap.to(boxRef.current, {
    //   rotation: "+=360"
    // });


    const HeroTimeLine = gsap.timeline({defaults: {duration: 1}});



    //no more repitition of duration: 1!
    HeroTimeLine
      .from(ownerMessage.current, {
        duration: 0.5, 
        delay: 1,
        x: -60,
        y: 40,
        ease: "back.out(2)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".unsold", {
        duration: 1, 
        x: 0,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".first", {
        duration: 0.5, 
        x: 0,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(chippersRef.current, {
        duration: 0.5, 
        x: -60,
        y: 40,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".second", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".third", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".fourth", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".fifth", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".more", {
        duration: 0.5, 
        x: 60,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .to(".required-order", {
        duration: 0.5, 
        x: 0,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })
      .from(".sold", {
        duration: 0.5, 
        x: 0,
        ease: "back.out(1.7)",
        yoyo: true,
        scale: 1,
        opacity: 0
      })

    // FAQs
    bulmaCollapsible.attach(".is-collapsible")
  
  });



  return (
    <div>
      <section className="hero cheapin-hero">
        <div className="hero-body">
          <div className="container is-column">
            <div className="columns is-vcentered">
              <div className="column is-6">
                <h2 className="title hero-heading">
                  Accept Orders, <br /> From group buyers.
                </h2>
                <p className="subtitle hero-subheading">
                  Let your customers and friends (o kahit di mo kakilala) to group buy, Cheap In will help to boost your sales and profit.
                </p>
                <p className="hero-ad-text">
                  No app or code required. Libre!
                </p>
                <a href="/contact/waitlist" className="button is-large is-black">
                  Sign up for the waiting list.
                </a> <br /> <br />
                <p className="hero-announce">
                  <span className="tag is-link">BETA</span> &nbsp;
                  We're on beta testing with selected merchants.
                </p>
              </div>
              <div className="column is-6 has-text-centered">
                <div>
                  <div className="owner">
                    <img src={Pao} className="customer" />
                    <p className="from-them" ref={ownerMessage}>₱300 for 50 people only!</p>
                  </div>
                  <img src={HeroThumbnail} className="hero-thumbnail" />
                  <div className="required-order unsold">
                    <h4>50</h4>
                    <small>Required</small>
                  </div>
                  <div className="required-order sold">
                    <div className="count">
                      <h4>Sold!</h4>
                    </div>
                  </div>
                  <img src={HeroElement} ref={boxRef} className="hero-element" />
                  <div className="group-buyers">
                    <div className="leader">
                      <img src={Paul} className="customer first" />
                      <p className="from-me" ref={chippersRef}>Bought 1, <br /> Chip in na Friends!</p>
                    </div>
                    <div className="friends">
                      <img src={Bianca} className="customer second" />
                      <img src={Japoy} className="customer third" />
                      <img src={Scott} className="customer fourth" />
                      <img src={Jeff} className="customer fifth" />
                      <div className="more">
                        <h4>40+</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="how-it-works" className="hero new-kkb">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-12 has-text-centered">
                <h1 className="section-title">
                The new KKB. 🇵🇭
                </h1>
                <p>We're on a mission to transform independent businesses to accept online orders.</p>
              </div>
            </div>
          </div>
          <div className="container ">
            <div className="columns">
              <div className="column is-4 entry">
                <img src={ExpandReach} className="image" />
                <h5 className="title">Expand Reach</h5>
                <p className="description">
                  Offer your customers the opportunity to purchase by groups. Offering savings to everyone. Thus, gaining new customers!
                </p>
              </div>
              <div className="column is-4 entry">
                <img src={ReduceSpend} className="image" />
                <h5 className="title">Reduce Marketing Spend</h5>
                <p className="description">
                  Take full advantage of this through FREE word-of-mouth marketing. Like, "Tol, alam mo yung Cheap In?".
                </p>
              </div>
              <div className="column is-4 entry">
                <img src={SecureOrders} className="image" />
                <h5 className="title">Secure Orders</h5>
                <p className="description">
                  Allows customers to complete and unlock a deal when they add more products to their cart with the same discount.
                </p>
              </div>
            </div>
            {/* <div className="columns is-centered has-text-centered demo-link">
              <a href="https://beta.cheapin.co/product/6149ac01d3456a0dc3e272e4" target="_blank" className="button is-large is-warning">
                Check mo yung demo. →
              </a>
            </div> */}
            <div className="first-element">
              <img src={DoubleCircle} alt="first element" />
            </div>
          </div>
        </div>
      </section>
      <section id="features" className="hero features">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-12 has-text-centered">
                <h1 className="section-title">
                  Features
                </h1>
                <p>We're on a mission to transform independent businesses to accept online orders.</p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns is-centered is-vcentered group-page">
              <div className="column is-4 entry">
                <h5 className="title">Group Discount and Page</h5>
                <p className="description">
                  A dedicated group buying page for your customers until the sale ended or supplies are sold.
                </p>
              </div>
              <div className="column is-6 has-text-centered entry">
                <img src={GroupPage} className="preview" />
                <img src={GroupPageElement} className="element" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns is-centered is-vcentered accept-payments reverse-columns">
              <div className="column is-6 has-text-centered entry">
                <img src={PaymentCheckout} className="preview" />
                <img src={PaymentCheckoutElement} className="element" />
              </div>
              <div className="column is-5 entry">
                <h5 className="title">Accept payments that are accessible to Filipinos anywhere, anytime!</h5>
                <p className="description">
                  Automatically collect payments from the most used platforms via GCash, GrabPay, Debit/Credit Card, and more.
                </p>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns is-centered is-vcentered set-rules">
              <div className="column is-4 entry">
                <h5 className="title">Setup your own rules.</h5>
                <p className="description">
                  You're the one in charge. Set your group buying rules through duration, price, quantity etc.
                </p>
              </div>
              <div className="column is-6 has-text-centered entry">
                <img src={Rules} className="preview" />
                <img src={RulesElement} className="element" />
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns is-centered is-vcentered reverse-columns same-day">
              <div className="column is-6 has-text-centered entry">
                <img src={Borzo} className="preview" />
                <img src={BorzoElement} className="element" />
              </div>
              <div className="column is-6 entry">
                <h5 className="title">Same-Day Delivery</h5>
                <p className="description">
                  We integrated Borzo (Formerly Mr. Speedy) for you delivery. Same day your order will be delivered to your customers, once the sale is fullfilled.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="how-it-works" className="hero how-it-works">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-12 has-text-centered">
                <h1 className="section-title">
                Start the group buy in minutes.
                </h1>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="columns">
              <div className="column is-4 entry">
                <img src={Create} className="image" />
                <h5 className="title">1. Create a Product</h5>
                <p className="description">
                  After signing up, you can create products and set rules for them.
                </p>
              </div>
              <div className="column is-4 entry">
                <img src={Cascade} className="image" />
                <h5 className="title">2. Share your Store/Product</h5>
                <p className="description">
                  Share your link to your Facebook communities, friends or any other channel to start your customer in Cheap In!
                </p>
              </div>
              <div className="column is-4 entry">
                <img src={Chill} className="image" />
                <h5 className="title">3. Chill, Relax and Wait.</h5>
                <p className="description">
                  Once you started, just sit back and relax while your customer chipping-in to order. And oh, of course, prepare packing!
                </p>
              </div>
            </div>
            <div className="second-element">
              <img src={DoubleSquared} alt="first element" />
            </div>
          </div>
          {/* <div className="columns is-centered has-text-centered demo-link">
            <a href="https://beta.cheapin.co/product/6149ac01d3456a0dc3e272e4" target="_blank" className="button is-large is-warning">
              Check mo yung demo. →
            </a>
          </div> */}
        </div>
      </section>
      <section id="faq" className="hero faq">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-12 has-text-centered">
                <h1 className="section-title">
                FAQs
                </h1>
                <p>Mga tanong na basic. Pwede kang mag dagdag. Chat ka lang. :)</p>
              </div>
            </div>
          </div>
          <div className="container ">
            <div className="columns">
              <div className="column is-12 entry">
                <div id="accordion">
                  <article className="message">
                    <div className="message-header">
                      <p>
                        <a href="#collapsible-message-accordion-second-1" data-action="collapse">
                          How much does it cost?
                          <span className="expand-icon">+</span>
                        </a>
                      </p>
                    </div>
                    <div id="collapsible-message-accordion-second-1" className="message-body is-collapsible" data-parent="accordion" data-allow-multiple="true">
                      <div className="message-body-content">
                        Cheap In is free for small businesses! You can buy optional upgrades if you want to support us.
                      </div>
                    </div>
                  </article>
                  <article className="message">
                    <div className="message-header">
                      <p>
                        <a href="#collapsible-message-accordion-second-2" data-action="collapse">
                          Where do my orders go?
                          <span className="expand-icon">+</span>
                        </a>
                      </p>
                    </div>
                    <div id="collapsible-message-accordion-second-2" className="message-body is-collapsible" data-parent="accordion" data-allow-multiple="true">
                      <div className="message-body-content">
                        We've built an order management system for you. Simply log in to your admin dashboard and you'll be able to manage your orders, see your earnings, add products, and more!
                      </div>
                    </div>
                  </article>
                  <article className="message">
                    <div className="message-header">
                      <p>
                        <a href="#collapsible-message-accordion-second-3" data-action="collapse">
                          How do I receive payments?
                          <span className="expand-icon">+</span>
                        </a>
                      </p>
                    </div>
                    <div id="collapsible-message-accordion-second-3" className="message-body is-collapsible" data-parent="accordion" data-allow-multiple="true">
                      <div className="message-body-content">
                        You can fully customize how you wish to be paid. We support all payment methods from credit cards to bank transfer, GrabPay and GCash.
                      </div>
                    </div>
                  </article>
                  <article className="message">
                    <div className="message-header">
                      <p>
                        <a href="#collapsible-message-accordion-second-4" data-action="collapse">
                          What fees are there?
                          <span className="expand-icon">+</span>
                        </a>
                      </p>
                    </div>
                    <div id="collapsible-message-accordion-second-4" className="message-body is-collapsible" data-parent="accordion" data-allow-multiple="true">
                      <div className="message-body-content">
                        For payments direct to your account, there are no fees. For credit/debit card payments, we use bux.ph which has a standard 3-4% for all merchants.
                      </div>
                    </div>
                  </article>
                  <article className="message">
                    <div className="message-header">
                      <p>
                        <a href="#collapsible-message-accordion-second-5" data-action="collapse">
                          How long does it take to get set up?
                          <span className="expand-icon">+</span>
                        </a>
                      </p>
                    </div>
                    <div id="collapsible-message-accordion-second-5" className="message-body is-collapsible" data-parent="accordion" data-allow-multiple="true">
                      <div className="message-body-content">
                        Literally less than 5 minutes! You can get started right away.
                      </div>
                    </div>
                  </article>
                  <article className="message">
                    <div className="message-header">
                      <p>
                        <a href="#collapsible-message-accordion-second-6" data-action="collapse">
                          Which countries do you support?
                          <span className="expand-icon">+</span>
                        </a>
                      </p>
                    </div>
                    <div id="collapsible-message-accordion-second-6" className="message-body is-collapsible" data-parent="accordion" data-allow-multiple="true">
                      <div className="message-body-content">
                        Anyone can use Cheap In in the Philippines for now. Soon, we'll plan on going global!
                      </div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="contact-us" className="hero contact-us">
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-4">
                <h1 className="section-title">
                Tara, Usap tayo. Saglit lang.
                </h1>
                <p className="section-subtitle">
                  Take a moment to let us know your details about your business. Sign up now, and our sales representative will talk to you soon.
                </p>
                <a href="/contact" className="button is-large is-warning">Book mo lang kami.  → </a>
              </div>
              <div className="column is-8 contact-element">
                <img src={ContactElement} alt="contact-element" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

IndexPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <IndexPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`
